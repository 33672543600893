<template>
    <v-app-bar app class="background" hide-on-scroll>
        <v-app-bar-nav-icon class="hidden-md-and-up" @click="toggleDrawer" />
        <v-container class="mx-auto py-0">
            <v-row align="center" class="container">
                <v-img :src="require('@/assets/logo.png')" class="mr-5" contain height="55" width="55" max-width="60" @click="$vuetify.goTo(0)" />
                <v-btn :ripple="false" v-for="item in links" :key="item.text" :to="{ name: item.link }" link class="hidden-sm-and-down" text exact>
                    {{ item.text }}
                </v-btn>
                <v-spacer />
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "CoreAppBar",
    computed: {
        ...mapGetters(["links"]),
    },
    methods: {
        ...mapMutations(["toggleDrawer"]),
    },
};
</script>

<style lang="scss" scoped>
.v-btn--active {
    color: rgb(160, 88, 6) !important;
}
.v-btn:hover::before {
    opacity: 0;
}
.v-btn--active:hover::before {
    opacity: 0;
}
.v-btn--active::before {
    opacity: 0;
}

.background {
    background: rgb(246, 249, 254); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fe', endColorstr='#e2f0fe',GradientType=0 ); /* IE6-9 */
}
.container a {
    border-bottom: solid;
    border-bottom-color: rgba(65, 105, 225, 0);
    font-weight: bold;
    font-size: 20px;
    font-family: "GloriaHallelujah" !important;
    color: #000b0e;
    text-transform: uppercase;
    transition: 0.5s;
    font-style: italic !important;

    &::after {
        position: absolute;
        content: "";
        top: 100%;

        width: 100%;
        height: 3px;
        background: #355681 !important;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.5s;
    }

    &:hover {
        color: rgb(160, 88, 6);
    }

    &:hover::after {
        transform: scaleX(1);
        transform-origin: center;
    }
}
</style>
